<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>College List</v-toolbar-title>
            <v-spacer></v-spacer>
            <add-college-dialog @new-college="onNewCollege"></add-college-dialog>
          </v-toolbar>
          <loading-bar :value="loading"></loading-bar>
          <v-window
            v-model="window"
          >
            <!-- College List -->
            <v-window-item :key="0">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="fas fa-search"
                  label="Search"
                  single-line
                  hide-details clearable
                ></v-text-field>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="colleges"
                item-key="id"
                :loading="loading"
                :search="search"
              >
                <template v-slot:[`item.logo`]="{ item }">
                  <v-img
                    :src="item.logoUrl"
                    max-height="50px"
                    max-width="50px"
                    contain
                  ></v-img>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    color="color3 color3Text--text"
                    @click.stop="handleClick(item)"
                    text
                  >
                    <v-icon>fas fa-eye</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-window-item>
            <!-- College Details -->
            <v-window-item :key="1">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <v-card v-if="selected">
                      <v-toolbar color="color2 color2Text--text">
                        <v-toolbar-title>{{selected.name}}</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-data-table
                          :headers="dHeaders"
                          :items="details"
                          item-key="label"
                          hide-default-footer
                          hide-default-header
                          :items-per-page="-1"
                          :loading="patching"
                        >
                          <template v-slot:[`item.value`]="{item}">
                            <div v-if="item.label === 'id'">{{item.value}}</div>
                            <v-edit-dialog
                              v-else
                              :return-value.sync="item.value"
                              large
                              persistent
                              @save="save(item)"
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <div v-on="on">{{ item.value || 'Click to enter' }}</div>
                                </template>
                                <span>{{ item.value ? 'Click to Edit' : 'Click to enter' }}</span>
                              </v-tooltip>
                              <template v-slot:input>
                                <div class="mt-4 title">
                                  {{item.value ? 'Update' : 'Enter'}} {{item.label}}
                                </div>
                                <v-text-field
                                  v-model="item.value"
                                  label="Edit"
                                  single-line
                                  autofocus
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                        </v-data-table>
                      </v-card-text>
                      <v-card-text v-if="selected">
                        <v-data-table
                          :headers="[
                            { text: 'Name', value: 'name' },
                            { text: 'Email', value: 'email' },
                            { text: 'Title', value: 'title' },
                            { text: '', value: 'action' }
                          ]"
                          :items="selected.coaches.filter(f => !f.deleted && f.id)"
                          hide-default-footer
                          :items-per-page="-1"
                          item-key="id"
                          :loading="patching"
                        >
                          <template v-slot:[`item.name`]="{item}">
                            <v-icon small class="mr-3">fas fa-user-{{item.userId ? 'check' : 'slash'}}</v-icon>{{item.name}}
                          </template>
                          <template v-slot:[`item.action`]="{item}">
                            <v-btn
                              color="error"
                              small text icon
                              @click.stop="confirmDelete(item)"
                            >
                              <v-icon>fas fa-trash</v-icon>
                            </v-btn>
                            <coach-invite-dialog :ccId="item.id" v-if="!item.userId"></coach-invite-dialog>
                            <v-btn color="color3" small text icon @click.stop="getQrInvite(item.id)"  v-if="!item.userId">
                              <v-icon>fas fa-qrcode</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                        <add-coach-dialog :college="selected"></add-coach-dialog>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn color="color3" text small @click="getData" :loading="loading">
          <v-icon class="small">fas fa-sync-alt</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        v-if="id"
        color="color3 color3Text--text"
        fab bottom fixed @click="() => {$router.back()}"
      >
        <v-icon>fas fa-caret-left</v-icon>
      </v-btn>
    </v-fab-transition>
    <vbl-confirm-dialog
      :ask="confirm"
      :question="selectedCoach && selected ? `Are you sure you want to remove ${selectedCoach.name} from ${selected.name}?` : ''"
      @confirm="doDelete"
      @deny="confirm = false"
    ></vbl-confirm-dialog>
    <qr-dialog :url="invite" ref="qr"></qr-dialog>
  </v-container>
</template>

<script>
import AddCoachDialog from '@/components/Colleges/AddCoachDialog'
import AddCollegeDialog from '@/components/Colleges/AddCollegeDialog'
import CoachInviteDialog from '@/components/Colleges/CoachInviteDialog'
import QrDialog from '@/components/Utils/QRDialog'

export default {
  props: ['id'],
  data () {
    return {
      loading: false,
      patching: false,
      search: null,
      colleges: [],
      selected: null,
      details: [],
      coaches: [],
      dialog: false,
      window: 0,
      selectedCoach: null,
      headers: [
        {
          text: 'Logo',
          value: 'logo'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Abbreviation',
          value: 'abbreviation'
        },
        {
          text: 'CBVB Id',
          value: 'cbvbId'
        },
        {
          text: '',
          value: 'actions',
          align: 'end'
        }
      ],
      dHeaders: [
        {
          text: 'Prop',
          value: 'label'
        },
        {
          text: '',
          value: 'value'
        }
      ],
      qrLoad: false,
      invite: null
    }
  },
  computed: {
    confirm: {
      get () {
        return !!this.selectedCoach
      },
      set () {
        this.selectedCoach = null
      }
    }
  },
  methods: {
    getQrInvite (id) {
      this.qrLoad = true
      this.$VBL.college.coach.getQrInvite(id)
        .then(r => {
          this.invite = encodeURIComponent(`https://volleyballlife.com${r.data}`)
          this.$refs.qr.open()
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.qrLoad = false })
    },
    getData (force) {
      if (this.colleges.length && !force) return
      this.loading = true
      this.$VBL.college.details.get()
        .then(r => {
          this.colleges = r.data
          this.checkSelected()
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    handleClick (college) {
      this.$router.push(`/admin/college/${college.id}`)
    },
    checkSelected () {
      if (this.id && this.colleges.length) {
        const c = this.colleges.find(f => f.id === +this.id)
        c && this.selectCollege(c)
      } else {
        this.window = 0
        this.search = null
      }
    },
    updateCollege (college) {
      const i = this.colleges.findIndex(f => f.id === +this.id)
      if (i > -1) {
        this.colleges.splice(i, 1, college)
        this.selectCollege(college)
      }
    },
    updateCoach (coach) {
      const i = this.selected.coaches.findIndex(f => f.id === +this.id)
      if (i > -1) {
        this.selected.coaches.splice(i, 1, coach)
      }
    },
    selectCollege (college) {
      this.selected = college
      this.details = []
      for (const key in college) {
        if (!['coaches'].includes(key)) this.details.push({ label: key, value: college[key] })
      }
      this.window = 1
    },
    save (row) {
      if (row && this.selected) {
        const dto = { id: this.selected.id }
        dto[row.label] = row.value
        this.patching = true
        this.$VBL.college.details.patch(dto)
          .then(r => {
            this.updateCollege(r.data)
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.patching = false
          })
      }
    },
    confirmDelete (item) {
      this.selectedCoach = item
    },
    doDelete () {
      if (this.selectedCoach) {
        this.patching = true
        const dto = {
          id: this.selectedCoach.id,
          deleted: true
        }
        this.$VBL.college.coach.patch(dto)
          .then(r => {
            this.updateCoach(r.data)
            this.confirm = false
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.patching = false
          })
      }
    },
    onNewCollege (dto) {
      this.colleges.push(dto)
      this.handleClick(dto)
    }
  },
  mounted () {
    this.getData()
  },
  watch: {
    id: 'checkSelected'
  },
  components: {
    AddCoachDialog,
    AddCollegeDialog,
    CoachInviteDialog,
    QrDialog
  }
}
</script>
