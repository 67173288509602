var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("College List")]),_c('v-spacer'),_c('add-college-dialog',{on:{"new-college":_vm.onNewCollege}})],1),_c('loading-bar',{attrs:{"value":_vm.loading}}),_c('v-window',{model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{key:0},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.colleges,"item-key":"id","loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.logoUrl,"max-height":"50px","max-width":"50px","contain":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"color3 color3Text--text","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick(item)}}},[_c('v-icon',[_vm._v("fas fa-eye")])],1)]}}],null,true)})],1),_c('v-window-item',{key:1},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.selected)?_c('v-card',[_c('v-toolbar',{attrs:{"color":"color2 color2Text--text"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.selected.name))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.dHeaders,"items":_vm.details,"item-key":"label","hide-default-footer":"","hide-default-header":"","items-per-page":-1,"loading":_vm.patching},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.label === 'id')?_c('div',[_vm._v(_vm._s(item.value))]):_c('v-edit-dialog',{attrs:{"return-value":item.value,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "value", $event)},"update:return-value":function($event){return _vm.$set(item, "value", $event)},"save":function($event){return _vm.save(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 title"},[_vm._v(" "+_vm._s(item.value ? 'Update' : 'Enter')+" "+_vm._s(item.label)+" ")]),_c('v-text-field',{attrs:{"label":"Edit","single-line":"","autofocus":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(item.value || 'Click to enter'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.value ? 'Click to Edit' : 'Click to enter'))])])],1)]}}],null,true)})],1),(_vm.selected)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":[
                          { text: 'Name', value: 'name' },
                          { text: 'Email', value: 'email' },
                          { text: 'Title', value: 'title' },
                          { text: '', value: 'action' }
                        ],"items":_vm.selected.coaches.filter(function (f) { return !f.deleted && f.id; }),"hide-default-footer":"","items-per-page":-1,"item-key":"id","loading":_vm.patching},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("fas fa-user-"+_vm._s(item.userId ? 'check' : 'slash'))]),_vm._v(_vm._s(item.name)+" ")]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","small":"","text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(item)}}},[_c('v-icon',[_vm._v("fas fa-trash")])],1),(!item.userId)?_c('coach-invite-dialog',{attrs:{"ccId":item.id}}):_vm._e(),(!item.userId)?_c('v-btn',{attrs:{"color":"color3","small":"","text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.getQrInvite(item.id)}}},[_c('v-icon',[_vm._v("fas fa-qrcode")])],1):_vm._e()]}}],null,true)}),_c('add-coach-dialog',{attrs:{"college":_vm.selected}})],1):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"color3","text":"","small":"","loading":_vm.loading},on:{"click":_vm.getData}},[_c('v-icon',{staticClass:"small"},[_vm._v("fas fa-sync-alt")])],1)],1)],1),_c('v-fab-transition',[(_vm.id)?_c('v-btn',{attrs:{"color":"color3 color3Text--text","fab":"","bottom":"","fixed":""},on:{"click":function () {_vm.$router.back()}}},[_c('v-icon',[_vm._v("fas fa-caret-left")])],1):_vm._e()],1),_c('vbl-confirm-dialog',{attrs:{"ask":_vm.confirm,"question":_vm.selectedCoach && _vm.selected ? ("Are you sure you want to remove " + (_vm.selectedCoach.name) + " from " + (_vm.selected.name) + "?") : ''},on:{"confirm":_vm.doDelete,"deny":function($event){_vm.confirm = false}}}),_c('qr-dialog',{ref:"qr",attrs:{"url":_vm.invite}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }