<template>
    <v-dialog
      v-model="dialog"
      max-height="90vh"
      max-width="90vh"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          :color="color || 'white'"
          v-on="on"
          small
          icon
        >
          <v-icon>fal fa-qrcode</v-icon>
        </v-btn>
      </template>
        <v-responsive :aspect-ratio="1">
        <v-card
          height="100%"
          width="100%"
        >
          <div class="text-end" style="float: right;">
            <v-btn color="grey" text icon @click.stop="dialog = false">
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </div>
          <v-img
            :src="src"
            lazy-src="https://vblimages.blob.core.windows.net/images/ce1a5207-6f6f-4759-93cb-d9ad9c523bd1.jpg"
            max-width="100%"
            max-height="100%"
            :key="refreshKey"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <div class="fill-height d-flex align-end justify-center">
              <v-btn color="color3" text small @click.prevent="download" :loading="loading">download</v-btn>
            </div>
          </v-img>
          <div v-if="user && user.id === -11">{{src}}</div>
        </v-card>
        </v-responsive>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { qrCodeBase } from '@/classes/_URL'
const pdfMake = () => import('pdfmake/build/pdfmake.js')
const pdfFonts = () => import('pdfmake/build/vfs_fonts.js')

export default {
  props: ['url', 'color'],
  data () {
    return {
      dialog: false,
      loading: false,
      refreshKey: 1
    }
  },
  computed: {
    ...mapGetters(['user']),
    src () {
      return this.refreshKey && `${qrCodeBase}&url=${this.url || window.location.href}`
      // return 'https://bad.src/not/valid'
    },
    fileName () {
      return this.refreshKey && this.generateNameFromUrl(this.myUrl)
    },
    myUrl () {
      return this.refreshKey && (this.url || window.location.href)
    },
    dd () {
      return this.refreshKey && {
        footer: {
          columns: [{ style: 'url', text: this.myUrl }]
        },
        content: [
          { image: 'qr', width: 600, style: 'img', margin: [0, -30, 0, 0] },
          { image: 'scanMe', width: 500, style: 'img', margin: [0, -40, 0, 0] }
        ],
        images: {
          qr: this.src,
          scanMe: 'https://vblimages.blob.core.windows.net/images/152184d7-12af-4207-9b77-6c394945b655.png'
        },
        styles: {
          img: {
            fontSize: 2,
            alignment: 'center'
          },
          url: {
            fontSize: 12,
            bold: false,
            alignment: 'center',
            color: '#A6A6A6'
          }
        }
      }
    }
  },
  methods: {
    open () {
      this.refreshKey++
      this.dialog = true
    },
    generateNameFromUrl (url) {
      let uniqueName = url.replace('https://', '').replace('http://', '').replaceAll('.', '_').replaceAll('/', '_')
      if (uniqueName.endsWith('_')) {
        uniqueName = uniqueName.substring(0, uniqueName.length - 1)
      }
      return uniqueName
    },
    download () {
      this.loading = true
      Promise.all([pdfMake(), pdfFonts()])
        .then(data => {
          const p = data[0]
          const f = data[1]
          window.pdfMake.vfs = f.pdfMake.vfs

          const n = this.fileName
          p.createPdf(this.dd).download(n, () => {
            this.loading = false
          })
        })
    }
  },
  watch: {
    $route: function () {
      this.refreshKey++
    }
  }
}
</script>
