<template>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="color3"
          v-on="on"
          small
          icon
          :loading="loading"
          :disabled="disabled"
        >
          <v-icon>fas fa-envelope</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          Invite History
        </v-toolbar>
        <v-card-text class="px-0">
          <v-window v-model="window" touchless>
            <v-window-item :key="0">
              <v-data-table
                :headers="[
                  {text: 'Sent', value: 'dtSent'},
                  {text: 'By', value: 'by'},
                  {text: '', value: 'view'},
                ]"
                :items="hist"
                item-key="id"
                :hide-default-footer="hist.length < 10"
                :loading="loading"
                disable-sort
              >
                <template v-slot:[`item.dtSent`]="{item}">
                  <div>{{item.dtSent | unixToLocalDt}}</div>
                  <div class="caption grey--text">{{item.to | formatArray}}</div>
                </template>
              </v-data-table>
            </v-window-item>
            <v-window-item :key="1">
              <div class="px-3 pt-2">
                <div class="title text-center">Resend an invitation?</div>
              </div>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-container fluid class="py-0">
          <v-row class="pa-0">
            <v-col cols="12" class="pa-0">
              <v-window v-model="window" touchless>
                <v-window-item :key="0">
                  <v-btn
                    color="color3"
                    text
                    @click.stop="window = 1"
                  >resend</v-btn>
                </v-window-item>
                <v-window-item :key="1">
                  <div class="justify-space-between d-flex">
                    <v-btn
                      color="color3Text color3--text"
                      small
                      fab
                      @click.stop="window = 0"
                      :disabled="loading"
                    >
                      <v-icon>fas fa-caret-left</v-icon>
                    </v-btn>
                    <v-btn
                      color="success"
                      :loading="loading"
                      @click.stop="sendInvite"
                    >
                      Send
                    </v-btn>
                  </div>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>

export default {
  props: ['disabled', 'ccId'],
  data () {
    return {
      dialog: false,
      loading: false,
      hist: [],
      onFile: true,
      iRecipients: [],
      showAdd: false,
      window: 0,
      search: null
    }
  },
  computed: {
  },
  methods: {
    viewHistory () {
      this.loading = true
      this.$VBL.college.coach.inviteHistory(this.ccId)
        .then(r => {
          this.hist = r.data
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    },
    sendInvite () {
      this.loading = true
      this.$VBL.college.coach.invite(this.ccId)
        .then(r => {
          this.hist = r.data
          this.window = 0
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dialog: function (v) {
      v && this.viewHistory()
      this.window = 0
    }
  }
}
</script>
