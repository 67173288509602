<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <span>Add A Coach</span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            v-on="{ ...d, ...tt }"
            color="color3 color3Text--text"
            fab
            bottom
            right
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Add A Coach</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-window v-model="window">
          <v-window-item value="add">
            <v-card flat>
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      label="Email"
                      hint="Enter the email address of the person you would like to add"
                      v-model="emailSearch"
                      type="search"
                      autocomplete="false"
                      persistent-hint
                      :rules="$v.emailSearch.$dirty ? [
                        () => $v.emailSearch.email || 'A valid email is required'
                      ] : []"
                      @input="$v.emailSearch.$touch();"
                    ></v-text-field>
                  </v-col>
                  <v-alert type="error" :value="!!this.addError" transition="scale-transition">
                    {{this.addError}}
                  </v-alert>
                </v-row>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-fab-transition>
                  <v-btn
                    fab
                    small
                    color="color3Text color3--text"
                    @click.stop="userSearch"
                    :disabled="!$v.$dirty || $v.$invalid"
                    :loading="searching"
                  >
                    <v-icon>fas fa-caret-right</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-card-actions>
            </v-card>
          </v-window-item>
          <v-window-item value="confirmAdd">
            <v-card flat>
              <v-container>
                <v-row dense v-if="searchResults.length > 1">
                  <v-col cols="12">
                    We found {{searchResults.length}} users with a confirmed email address of: <b>{{emailSearch}}</b>.<br>
                    Please select the user you would like to add
                  </v-col>
                  <v-select
                    :items="searchResults"
                    v-model="newAdmin"
                    label="Users"
                    :item-text="(item) => `${item.firstName} ${item.lastName}`"
                    return-object
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-row>
                <v-expand-transition>
                  <div v-if="newAdmin">
                    <v-alert type="error"
                      v-if="!!addError"
                      :value="true"
                      transition="scale-transition"
                      :key="newAdmin.id"
                    >
                      {{addError}}
                    </v-alert>
                    <v-row dense v-else>
                      <v-col cols="12" class="title" v-if="newAdmin.id">
                        Add {{newAdmin.firstName}} {{newAdmin.lastName}} as
                      </v-col>
                      <template v-else>
                        <v-col cols="12">
                          We were unable to find a user with a confirmed email address of: <b>{{emailSearch}}</b>.<br>
                          Enter his or her name and we will send them an invite.
                          Once he or she confirms their email address, they will be added to your orginazation.
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="First Name*"
                            v-model="newAdmin.firstName"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            label="Last Name*"
                            v-model="newAdmin.lastName"
                          ></v-text-field>
                        </v-col>
                      </template>
                      <v-col cols="12">
                        <v-combobox
                          :items="['Head Coach', 'Assistant Coach']"
                          v-model="eRole"
                          label="Title"
                          color="color3"
                          item-color="color3"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </v-container>

              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  fab
                  small
                  color="color3Text color3--text"
                  @click.stop="dialogWindow = 'add'"
                  :disabled="loading"
                >
                  <v-icon>fas fa-caret-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success"
                  @click.stop="addMe"
                  :loading="loading"
                  :disabled="!newAdmin || !newAdmin.firstName || !newAdmin.lastName || !eRole || !!this.addError"
                >confirm</v-btn>
              </v-card-actions>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  props: ['college'],
  mixins: [validationMixin],
  validations () {
    return {
      emailSearch: { email }
    }
  },
  data () {
    return {
      dialog: false,
      window: 'add',
      emailSearch: null,
      addError: null,
      searching: false,
      searchResults: [],
      newAdmin: null,
      eRole: null,
      base: {
        firstName: '',
        lastName: '',
        emails: []
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['liveUpdates'])
  },
  methods: {
    onCloseDialog () {
      this.emailSearch = null
      this.searchResults = []
      this.newAdmin = null
      this.$v.$reset()
      this.window = 'add'
    },
    addMe () {
      if (!this.newAdmin) return
      const dto = {
        userId: this.newAdmin.id || null,
        collegeId: this.college.id,
        name: `${this.newAdmin.firstName} ${this.newAdmin.lastName}`,
        email: this.emailSearch,
        title: this.eRole,
        deleted: false
      }
      this.loading = true
      this.$VBL.college.coach.patch(dto)
        .then(r => {
          this.college.coaches.push(r.data)
          this.dialog = false
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    },
    userSearch () {
      this.searchResults = []
      this.searching = true
      this.newAdmin = JSON.parse(JSON.stringify(this.base))
      this.liveUpdates.invoke('UserEmailSearch', this.emailSearch)
        .then(r => {
          this.processSearch(r)
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
        })
    },
    processSearch (results) {
      this.searchResults = results
      if (results.length === 1) this.newAdmin = results[0]
      if (!this.addError) this.window = 'confirmAdd'
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) this.onCloseDialog()
    }
  }
}
</script>
