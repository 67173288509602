var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"500px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"color3","small":"","icon":"","loading":_vm.loading,"disabled":_vm.disabled}},on),[_c('v-icon',[_vm._v("fas fa-envelope")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text"}},[_vm._v(" Invite History ")]),_c('v-card-text',{staticClass:"px-0"},[_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{key:0},[_c('v-data-table',{attrs:{"headers":[
              {text: 'Sent', value: 'dtSent'},
              {text: 'By', value: 'by'},
              {text: '', value: 'view'} ],"items":_vm.hist,"item-key":"id","hide-default-footer":_vm.hist.length < 10,"loading":_vm.loading,"disable-sort":""},scopedSlots:_vm._u([{key:"item.dtSent",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("unixToLocalDt")(item.dtSent)))]),_c('div',{staticClass:"caption grey--text"},[_vm._v(_vm._s(_vm._f("formatArray")(item.to)))])]}}],null,true)})],1),_c('v-window-item',{key:1},[_c('div',{staticClass:"px-3 pt-2"},[_c('div',{staticClass:"title text-center"},[_vm._v("Resend an invitation?")])])])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{key:0},[_c('v-btn',{attrs:{"color":"color3","text":""},on:{"click":function($event){$event.stopPropagation();_vm.window = 1}}},[_vm._v("resend")])],1),_c('v-window-item',{key:1},[_c('div',{staticClass:"justify-space-between d-flex"},[_c('v-btn',{attrs:{"color":"color3Text color3--text","small":"","fab":"","disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();_vm.window = 0}}},[_c('v-icon',[_vm._v("fas fa-caret-left")])],1),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.sendInvite($event)}}},[_vm._v(" Send ")])],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }