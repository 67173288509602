<template>
  <v-dialog
    v-model="dialog"
    scrollable persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <span>Add A College</span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            v-on="{ ...d, ...tt }"
            color="color3 color3Text--text"
            fab
            small
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Add New College</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          label="College Name"
          v-model="name"
        ></v-text-field>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="success white--text"
          small fab
          :disabled="!name"
          :loading="loading"
          @click.stop="save"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      name: null,
      loading: false
    }
  },
  methods: {
    save () {
      this.loading = true
      this.$VBL.college.details.patch({ name: this.name })
        .then(r => {
          this.$emit('new-college', r.data)
          this.dialog = false
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    dialog: function (val) {
      this.name = null
      this.loading = false
    }
  }
}
</script>
